<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: calc(100vh - 34px);">
      <el-form
        label-position="top"
        :model="campaign"
        ref="triggerCampaignForm"
        label-width="100px"
        :rules="rules"
        class="form-container"
      >
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <PageHeader
              :title="__('Outbound Dialler')"
              v-if="!isChatBotTask"
            ></PageHeader>
            <PageHeader :title="__('Outbound Chat')" v-else></PageHeader>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Campaign Name')"
              prop="campaign_name"
            >
              {{ campaign.campaign_name }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Task Name')"
              prop="task_name"
            >
              {{ campaign.task_name }}
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="!isChatBotTask">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Campaign Caller Id')"
                prop="campaign_caller_id"
              >
                {{ campaign.campaign_caller_id }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="!isChatBotTask">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Phone Number')"
                prop="phone_number"
              >
                <div class="tooltips">
                  <!-- eslint-disable-next-line -->
                  {{ __("Enter a 4 to 14 digits phone number without any special characters, For example") }}: <b>1245</b> or <b>04123456789</b>
                </div>
                <el-input v-model="phone_number"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Passed Parameters')"
              prop="passed_param"
            >
              <div
                v-for="param_id in campaign.campaign_passed_params.data"
                v-bind:key="param_id"
                style="display: flex; align-items: center; width: 100%; justify-content: space-between"
              >
                <div style="display:flex;">
                  <h4>
                    {{ getParameterNameFromId(param_id) }}
                  </h4>
                </div>
                <div style="display:flex; width: 500px">
                  <el-input
                    v-model="passed_params[getParameterNameFromId(param_id)]"
                  ></el-input>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-button
                class="submitBtn"
                @click="submitForm('triggerCampaignForm')"
                :loading="loading"
                >{{ __("Trigger Campaign") }}
              </el-button>
              <el-button class="cancelBtn" @click="$emit('cancel')"
                >{{ __("Cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapGetters, mapActions, mapState } from "vuex";
import { TASK_TYPES } from "@/constants/nodes";
import _ from "lodash";

export default {
  name: "triggerCampaign",
  components: {
    PageHeader
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      phone_number: "",
      session_id: "",
      task_type: "",
      passed_params: {},
      rules: {}
    };
  },
  mounted() {
    _.map(this.campaign.campaign_passed_params.data, param_id => {
      this.$set(this.passed_params, this.getParameterNameFromId(param_id), "");
    });
  },
  computed: {
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables"
    }),
    ...mapState("campaigns", {
      loading: state => state.loading
    }),
    isChatBotTask() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT;
    }
  },
  methods: {
    ...mapActions("campaigns", {
      makeACall: "makeACall"
    }),
    getParameterNameFromId(param_id) {
      return _.find(this.passedParameterVariables, variable => {
        return variable.variable_id === param_id;
      }).variable_name;
    },
    submitForm() {
      this.$refs.triggerCampaignForm.validate(valid => {
        if (valid) {
          let campaign = {
            campaign_id: this.campaign.campaign_id,
            phone_number: this.phone_number,
            session_id: this.session_id,
            task_type: this.campaign.campaign_channel,
            ...this.passed_params
          };
          this.makeACall({ campaign })
            .then(res => {
              this.$message({
                type: "success",
                message: res.data.message
              });
            })
            .catch(err => {
              console.log(err);
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          console.log("error submit");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
</style>
